.profile-settings-container {
  .profile-photo {
    height: 200px;
    width: 200px;
    border-radius: 16px;
  }

  .ant-typography {
    margin: 0;
    color: #52c41a;
  }

  .menu-card {
    width: 160px;
    background-color: #f0f0f0;
    border: 1px solid lightgray;

    .ant-card-body {
      padding: 16px;
    }

    .ant-flex {
      height: 120px;
    }

    .menu-title {
      font-size: 16px;
      font-weight: 500;
      text-align: center;
    }
  }

  .anticon {
    font-size: 54px;
  }

  .anticon-user {
    color: #52c41a;
  }

  .anticon-picture {
    color: #4096ff;
  }

  .anticon-lock {
    color: #faad14;
  }

  .anticon-history {
    color: blue;
  }

  .anticon-safety {
    color: #52c41a;
  }

  .anticon-stop,
  .anticon-user-delete,
  .anticon-close-circle {
    color: #f5222d;
  }
}
