.admin-dashboard-container {
  font-weight: 600;
  font-size: 16px;

  .ant-form-item {
    margin-bottom: 0;
    width: 520px;
  }

  .ant-picker {
    width: -webkit-fill-available;
  }

  .ant-divider {
    border-color: gray;
  }

  .ant-card {
    text-align: center;
    border-color: gray;
    background-color: #f0f0f0;

    .ant-statistic-title {
      font-size: 16px;
      color: unset;
    }
  }

  .ant-form {
    margin-bottom: 24px;
  }
}
