@import "../../styles/variable.scss";

.login-container {
  margin: 24px;
  min-height: calc(100vh - 108px);

  .image-container {
    .ant-image-img {
      max-width: 780px;
      min-height: 620px;
    }
  }

  .form-container {
    font-weight: 600;

    .ant-typography {
      text-align: center;
      color: $primary-font-color;
    }

    .ant-card {
      border-radius: 24px;
      box-shadow: 0 10px 25px rgb(10 75 90);
    }

    .forgot-password {
      padding-bottom: 16px;
      :hover {
        cursor: pointer;
      }
    }

    .forgot-password,
    .register-link {
      color: red;
      text-decoration: none;
    }
  }
}

.forgot-password-confirm {
  .ant-modal-confirm-btns {
    text-align: center;
  }
}
