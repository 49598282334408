@import "../../styles/variable.scss";

.payment-table {
  table {
    font-size: 16px;
    font-weight: 600;
  }

  .ant-table-thead .ant-table-cell,
  td:first-child {
    font-weight: 700;
  }

  .price-container {
    font-size: 24px;
  }

  .anticon {
    font-size: 27px;
  }

  .anticon-close {
    color: red;
  }

  .anticon-check-circle {
    color: #52c41a;
  }
}
