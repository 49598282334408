@import "../../styles/variable.scss";

.privacy-policy-container {
  font-size: 16px;

  .ant-typography {
    color: $primary-font-color;
    text-align: center;
  }

  ul {
    margin-left: 32px;
  }
}
