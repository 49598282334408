@import "../../styles//variable.scss";

.about-container {
  font-size: 16px;

  .ant-typography {
    text-align: center;
  }

  .title {
    color: $primary-font-color;
  }

  p {
    text-indent: 32px;
  }
}
