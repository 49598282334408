$font-colour: #ffffff;
$primary-background: #009900;
$nav-select-background: #ffa600;
$trial-color: red;

.footer-container {
  background-color: $primary-background;
  color: $font-colour;
  min-height: 60px;
  padding: 16px;

  a {
    color: $font-colour;
    font-weight: 600;
  }
}
