/* Custom styles for Ant Design Table header */
$modal-user-details: #52c41a;
$accepted-color: #389e0d;
$requested-color: #faad14;
$rejected-color: #f5222d;

.page-title.ant-typography {
  text-align: center;
  margin-bottom: 24px;
}

.delete-search {
  .ant-form-item {
    margin: 0;
  }

  .ant-btn {
    width: 100px;
    // For Aligment purpose we added margin bottom.
    margin-bottom: 8px;
  }
}

.delete-approval-modal {
  .ant-modal-close {
    top: inherit;
  }

  .ant-modal-header {
    margin-bottom: 24px;

    .ant-modal-title {
      text-align: center;
      font-size: 20px;
    }
  }

  .user-details {
    span {
      color: $modal-user-details;
    }
    strong {
      font-weight: 600;
      font-size: 16px;
    }
  }

  .ant-modal-footer {
    text-align: center;
  }
}

.accepted,
.rejected,
.requested {
  font-weight: 500;
}

.accepted {
  color: $accepted-color;
}

.rejected {
  color: $rejected-color;
}

.requested {
  color: $requested-color;
}

.profile-details-description {
  .ant-descriptions-item-label {
    color: unset !important;
    font-weight: 600 !important;
    width: 50%;
  }
}
