$SUCCESS: #389e0d;
$WARNING: #faad14;
$ERROR: red;
$EDITED: #237804;
$UPGRADE: #722ed1;

.history-timeline-container {
  .ant-card-head-title {
    font-size: 18px;
  }

  .ant-timeline {
    .anticon {
      font-size: 20px;
    }
    .ant-timeline-item-tail {
      border-color: unset;
    }

    .ant-timeline-item-label {
      padding-right: 8px;
    }

    .ant-timeline-item-head-blue {
      color: unset;
    }

    .success {
      color: $SUCCESS;
    }

    .error {
      color: $ERROR;
    }

    .warning {
      color: $WARNING;
    }

    .upgrade {
      color: $UPGRADE;
    }

    .edited {
      color: $EDITED;
    }

    .ant-timeline-item-last {
      padding: 0;

      .ant-timeline-item-content {
        min-height: unset;
      }
    }
  }

  .content {
    color: black;
    font-weight: 500;
  }
}
