.home-container {
  font-weight: 600;

  .ant-card {
    border-radius: 16px;
  }
  .ant-image-img {
    border-radius: 8px;
  }
}
