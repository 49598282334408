$primary-color: #009900;
$BUTTON_LOADER: #1677ff;
$FAVOURITE_COLOR: #ff0000;
$WARNING_COLOR: #faad14;
$SEND_COLOR: #1890ff;
$CANCEL_COLOR: #8c8c8c;
$REJECTED_COLOR: #ff4d4f;
$APPROVED_COLOR: #389e0d;
$VIEW_COLOR: #fa8c16;
$INSTA_COLOR: #c13584;
$YOUTUBE_COLOR: #ff0000;
$FB_COLOR: #1877f2;
$CONFIRM_COLOR: #faad14;

.ad-container {
  --gap: 24px;
  gap: var(--gap);
  overflow: hidden;
  user-select: none;

  &:hover {
    ul {
      animation-play-state: paused;
    }
  }

  ul {
    display: flex;
    flex-shrink: 0;
    gap: var(--gap);
    animation: scroll 18s linear infinite;
    list-style-type: none;

    li {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 16px;

      a {
        display: flex;
        align-items: center;
        gap: 6px;
        color: unset;
      }
    }
  }
}

@keyframes scroll {
  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}

.user-profile-container {
  .ant-descriptions-header {
    margin: 0;
    padding: 16px;

    .ant-descriptions-title {
      font-size: 24px;
      text-align: center;
    }
  }
}

.user-profile-container.ant-descriptions-bordered
  > .ant-descriptions-view
  .ant-descriptions-row
  > .ant-descriptions-item-label {
  color: unset;
  text-align: end;
  font-weight: 700;
  background-color: #f0f0f0;
}

.profile-pic-container {
  .ant-image-img {
    max-height: 200px;
    border-radius: 16px;
  }

  .ant-image-mask {
    border-radius: 16px;
  }

  .ant-space {
    .ant-btn {
      width: 48px;
      height: 48px;
      background-color: #f0f0f0;

      .ant-btn-icon,
      .anticon {
        font-size: 30px;
      }
    }
  }

  .anticon-loading {
    color: $BUTTON_LOADER;
  }

  .anticon-heart {
    color: $FAVOURITE_COLOR;
  }

  .send-request {
    color: $SEND_COLOR;
  }

  .cancel-request {
    color: $CANCEL_COLOR;
  }

  .rejected {
    color: $REJECTED_COLOR;
  }

  .accepted {
    color: $APPROVED_COLOR;
  }

  .anticon-warning {
    color: $WARNING_COLOR;
  }

  .phone-icon {
    color: $APPROVED_COLOR;
    font-size: 26px;
  }

  .view-count-container {
    font-size: 18px;
    font-weight: 600;
    color: $VIEW_COLOR;
  }

  .last-active {
    color: $CANCEL_COLOR;
  }

  .profile-title {
    margin: 0;
    font-weight: bold;
    color: $primary-color;
  }
}

.profile-report-modal {
  .ant-modal-title {
    font-size: 24px;
    text-align: center;
  }

  textarea {
    resize: none;
  }
}

.confirm-modal {
  .ant-modal-title {
    margin-bottom: 16px;
  }

  .anticon-exclamation-circle {
    color: $CONFIRM_COLOR;
    font-size: 24px;
  }
}

.profile-edit-modal {
  .ant-modal-close {
    top: revert;
  }
}
