$primary-color: #009900;
$primary-card-background: #f0f0f0;
$FAVOURITE_COLOR: #ff0000;

.search-id-form,
.search-filter-form {
  .ant-form-item-label {
    font-weight: 600;
  }

  .ant-btn {
    width: 150px;
  }
}

.search-id-form {
  .ant-form-item {
    margin: 0;
  }
}

.search-filter-form {
  .ant-form-item-label {
    width: 180px;
  }
}

.ant-divider-horizontal.ant-divider-with-text-center::before,
.ant-divider-horizontal.ant-divider-with-text-center::after {
  border-top: 1px solid #494949;
}

.search-container {
  border-color: lightgray;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  .ant-card-body {
    padding: 16px;
  }

  .ant-image-img {
    max-height: 200px;
    border-radius: 16px;
  }

  .ant-btn {
    .ant-typography {
      color: unset;
    }
  }

  .profile-card {
    .ant-descriptions-title {
      font-size: 24px;
      text-align: center;
      color: $primary-color;
      white-space: wrap;
    }

    .ant-descriptions-view {
      .ant-descriptions-row > .ant-descriptions-item-label {
        color: unset;
        text-align: end;
        font-weight: 700;
        background-color: $primary-card-background;
      }
    }

    .anticon-heart {
      font-size: 30px;
      vertical-align: sub;
      color: $FAVOURITE_COLOR;
    }
  }
}

.filter-pagination {
  text-align: center;
}
