.register-container {
  max-width: 700px;
  margin: auto;

  h4 {
    color: #1677ff;
    text-align: center;
  }

  .ant-steps.ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
    min-height: unset;
  }

  .ant-steps.ant-steps-navigation {
    .ant-steps-item-icon,
    .ant-steps-item-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ant-steps-item::after {
      display: none;
    }
  }
}

.email-verify-modal {
  .anticon-mail {
    font-size: 72px;
  }

  .ant-input {
    width: 120px;
    text-align: center;
  }
}

.pp-eula-checkbox {
  text-align: center;

  #basic_details_is_pp_eula_accepted_help {
    margin-bottom: 16px;
  }

  .ant-btn {
    margin: 0;
    padding: 0;
  }
}
