@import "./styles/variable.scss";

.ant-form-item-label {
  font-weight: 600;
}

.ant-descriptions {
  .ant-descriptions-item-label {
    color: unset;
    font-weight: 600;
  }
}

.ant-table-wrapper {
  .ant-table.ant-table-bordered {
    > .ant-table-container {
      border-color: $table-border-color;

      > .ant-table-content > table {
        thead > tr > th {
          border-color: $table-border-color;
          background-color: $table-header-background;
          text-align: center;
        }

        tbody > tr > td {
          border-color: $table-border-color;
          text-align: center;
        }
      }

      .ant-table-cell-fix-left {
        background-color: $table-header-background;
        font-weight: 600;
      }
    }
  }
}

.ant-pagination {
  text-align: end;
}
