$custom-header-height: 60px;
$custom-footer-height: 60px;

//custom-color
$primary-background: #009900;
$primary-font-color: #009900;

$table-header-background: #f0f0f0;
$table-border-color: #d9d9d9;

//Approval Modal user details for admin
$modal-user-details: #52c41a;
