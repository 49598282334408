.photo-upload-modal {
  .ant-modal-header {
    margin-bottom: 16px;

    .ant-modal-title {
      font-size: 18px;
      text-align: center;
    }
  }

  .ant-modal-footer {
    margin-top: 16px;
  }

  .photo-upload {
    .ant-upload-drag-container {
      display: flex;
      justify-content: center;
      gap: 8px;
      font-size: 16px;
    }

    .ant-upload-list-item-thumbnail {
      cursor: unset;
      pointer-events: none;
    }
  }
}

.my-photo-container {
  .action-btn {
    margin-top: 16px;
  }
}
