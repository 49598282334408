.report-issue-modal {
  .ant-modal-title {
    text-align: center;
    font-size: 20px;
    margin-bottom: 16px;
  }

  .report-textarea textarea {
    resize: none;
  }
}
