@import "../../styles/variable.scss";

.main-layout {
  .content-card {
    border: none;
    > .ant-card-body {
      min-height: calc(100vh - $custom-header-height - $custom-footer-height);
    }
  }
}
