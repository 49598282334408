.profile-upgrade-container {
  .ant-typography {
    text-align: center;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .profile-form .ant-form-item {
    width: 450px;
  }
  .upgrade-form {
    .ant-form-item {
      max-width: 500px;
      width: -webkit-fill-available;
    }

    .ant-form-item-label {
      width: 160px;
    }
  }

  .ant-divider {
    margin: 0;
  }
}
