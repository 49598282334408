@import url(react-toastify/dist/ReactToastify.min.css);

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
