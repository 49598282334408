@import "../../../styles/variable.scss";

.report-container {
  .ant-typography {
    text-align: center;
    margin-bottom: 0;
  }

  .ant-form-item {
    margin-bottom: 0;
    width: 450px;
  }
}

.report-approval-modal {
  .ant-modal-title {
    font-size: 20px;
    text-align: center;
  }

  .ant-descriptions-item {
    .ant-descriptions-item-label {
      font-size: 16px;
      width: 100px;
      justify-content: space-between;
    }

    .ant-descriptions-item-content {
      padding-left: 16px;
      font-size: 16px;
      color: $modal-user-details;
      font-weight: 600;
    }
  }

  .ant-form-item-label {
    font-size: 16px;
  }
}
