$count-color: #d9f7be;
$primary-color: #05870e;
$table-border-color: #d9d9d9;
$table-header-color: #009900;

.dashboard-container {
  .ant-card {
    border: 2px solid $primary-color;
  }
}

.dashboard-header {
  .ant-typography {
    margin: 0;
  }
  .membership-type {
    color: red;
  }
}

.dashboard-body {
  .ant-card {
    width: 428px;
    border-radius: 25px;

    .ant-avatar {
      background-color: $count-color;

      .ant-avatar-string {
        color: black;
        font-size: 24px;
        font-weight: 600;
      }
    }

    .content {
      font-size: 16px;
    }
  }
}

.dashboard-details-container {
  .ant-modal-close {
    top: unset;
  }

  .ant-table-container,
  th,
  td {
    border-color: $table-border-color !important;
  }

  .ant-table-thead {
    .ant-table-cell {
      font-size: 16px;
      background-color: $table-header-color;
    }
  }

  .ant-table-pagination.ant-pagination {
    margin-bottom: 0;
  }
}
