$font-colour: #ffffff;
$primary-background: #009900;
$nav-select-background: #ffa600;
$trial-color: red;
$menu-icon-color: #ffffff;

.header-container {
  height: 100%;
  background-color: $primary-background;
  padding: 0 16px;

  .headertitlealldev {
    cursor: pointer;
  }

  .headertitlemobile {
    cursor: pointer;
  }

  .ant-typography {
    margin: 0;
    font-weight: 700;
    color: $font-colour;
  }

  .web-menu {
    padding: 10px 0;
    align-items: center;
    background-color: unset;
    justify-content: space-between;
    border: none;

    .ant-menu-overflow-item {
      color: $font-colour;
      font-weight: bold;
    }

    .ant-menu-overflow-item.ant-menu-item-selected {
      background-color: $nav-select-background;
      padding: 8px 16px;
      border-radius: 8px;
    }

    // Avatar selected style.
    .ant-menu-submenu-selected {
      .ant-menu-submenu-title {
        border-radius: 50% !important;
        box-shadow: 0px 0px 10px 5px $nav-select-background !important;
      }
    }

    .admin {
      margin-top: 15px;
      color: #fff;
    }

    // Removed antd default menu select style
    .ant-menu-item-active::after,
    .ant-menu-item-selected::after,
    .ant-menu-submenu::after {
      display: none;
    }
  }

  .close-icon {
    position: absolute;
    right: 16px;
  }
}

.ant-menu-submenu.ant-menu-submenu-popup.ant-menu-light.ant-menu-submenu-placement-bottomRight {
  text-align: center;

  .ant-menu {
    background-color: $primary-background;

    .ant-menu-item-selected {
      background-color: $nav-select-background;
    }

    .ant-menu-item-only-child {
      color: $font-colour;
    }
  }
}

.ant-btn {
  &:focus {
    outline: none;
  }
}

//Mobile view menu bar style
.mobile-menu {
  box-shadow: none;
  top: 60px !important;
  right: 0 !important;

  .ant-popover-inner {
    padding: 0;
    text-align: center;

    .ant-menu {
      background-color: $primary-background;
      width: 100vw;

      .ant-menu-item {
        color: $font-colour;

        &:active,
        &:hover {
          color: $font-colour;
        }

        &.ant-menu-item-selected {
          background-color: $nav-select-background;
        }

        &.ant-menu-item-only-child {
          padding: 0 16px !important;
        }
      }

      .ant-menu-title-content {
        padding: 6px 0;
      }

      // Avatar selected style.
      .ant-menu-submenu-selected {
        .ant-avatar {
          border-radius: 50%;
          box-shadow: 0px 0px 10px 5px $nav-select-background;
        }
      }

      .ant-menu-submenu-title {
        color: $font-colour;
        padding: 0 16px !important;
        height: fit-content;
      }
    }
  }
}

.menu-icon {
  color: $menu-icon-color;
}

.logo-container {
  cursor: pointer;
}

sup {
  color: $trial-color;
  font-weight: 400;
}
