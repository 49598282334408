@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
input,
textarea {
  font-family: "Poppins", sans-serif;
}

.icon {
  width: 28px;
  margin-right: 0.7rem;
}

// .social-media {
//   //   padding: 2rem 0 0 0;
//   position: absolute;
// }

.social-media p {
  color: #333;
  //   font-weight: bold !important;
}

.social-icons {
  display: flex;
  margin-top: 0.5rem;
  justify-content: center;
}

.social-icons a {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background-color: #009900 !important;
  color: #fff;
  text-align: center;
  line-height: 35px;
  margin-right: 0.5rem;
  transition: 0.3s;
}

.social-icons a:hover {
  transform: scale(1.05);
}

@media (max-width: 850px) {
  .text {
    margin: 1rem 0 1.5rem 0;
  }

  .social-media {
    // position: relative;
    padding: 1.5rem 0 0 0;
  }
}

@media (max-width: 480px) {
  .social-media p {
    font-size: 0.8rem;
    // font-weight: bold !important;
  }

  .social-icons a {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
}

.positionsocial {
  position: relative !important;
}

.fw {
  font-weight: bold !important;
}

//new  design

.contact-info {
  width: 50%;
}

.contact-info-item {
  display: flex;
  margin-bottom: 30px;
  cursor: pointer;
}

.contact-info-icon {
  height: 70px;
  width: 70px;
  background-color: #fff;
  text-align: center;
  border-radius: 50%;
}

.contact-info-icon i {
  font-size: 30px;
  line-height: 70px;
}

.contact-info-content {
  margin-left: 20px;
}

.contact-info-content h4 {
  color: #1da9c0;
  font-size: 1.4em;
  margin-bottom: 5px;
}

.contact-info-content p {
  // color: #fff;
  font-size: 1em;
}

@media (max-width: 991px) {
  .contact-info {
    margin-bottom: 40px;
    width: 100%;
  }
}

a:link {
  text-decoration: none !important;
}

a:visited {
  text-decoration: none !important;
}
